
export const getLanguages = () => {
  const languagesMeta = document.getElementById('saved_langs')?.content
  const parseLanguages = languagesMeta ? JSON.parse(languagesMeta) : []
  return parseLanguages.map((item) => ({
    ...item,
    value: item.id,
    label: item.name
  }))
}

export const getCurrentLanguage = () => {
  const languageMeta = document.getElementById('selected_lang')?.content
  const selectedLang = getLanguages().filter(
    (language) => language.code === JSON.parse(languageMeta)
  )
  return selectedLang[0] || {}
}

export const getAppMeta = (app_slug) => {
  const AppMeta = document.getElementById(app_slug)?.content
  return AppMeta
}

export const getTheme = () => {
  const theme = document.getElementById('theme_data')?.content
  return theme ? JSON.parse(theme) : {}
}

export const getIsAuthenticated = () => {
  let isAuthenticated = document.getElementById('is_authenticated')
  return isAuthenticated ? isAuthenticated.content === 'True' : 'False'
}

export const getAuth = () => {
  const isAuthenticated =
    document.getElementById('is_authenticated').content === 'true'
  const user = document.getElementById('user').content
  const parseUser = user ? JSON.parse(user) : null
  const isAdmin = 
    parseUser && (parseUser.is_staff || (parseUser.groups && parseUser.groups_ser[0].name === 'Administradores')) ? true : false
  return {
    isAuthenticated,
    isAdmin,
    user: parseUser,
    userId: parseUser ? parseUser.id : null
  }
}

export const getUserTags = () => {
  let CustomTags = document
    .getElementById('custom_tags')
    .getAttribute('content')
  return CustomTags ? JSON.parse(CustomTags) : []
}
