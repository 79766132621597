import { render } from "react-dom"
import React from "react"
import PopupS from "./components/PopupS"
import '../../../i18n-config'
let containerPopup = document.querySelector(".Popup-Father")

if (containerPopup) {
  let language = containerPopup.dataset.language
  render(<PopupS language={language} />, containerPopup)
}
