import React from "react";
import { useTranslation } from 'react-i18next'

const PopupS = (props) => {
  const { t } = useTranslation()
  let limitTimeProm = null

  limitTimeProm = (
    <React.Fragment>
      <div className="Popup-footer mid">
        <div className="Popup-footer-left">
          <div className="canna-ico1 mid"></div>
        </div>
        <a className="Popup-button mid" href="https://www.cognitoforms.com/cannaworks1/cannaidcapturaleadcorto" target="_blank"><p>{t("SOLICITA O RENUEVA TU LICENCIA")} X $30</p></a>
        <p>
          <p className="Popup-moreinfo">{t("mas información")}</p>
          <p className="Popup-tel"><img src="/assets/images/Popup-tel-ico.png" alt="telIco" /> (939)336-1443</p>
        </p>
        <p className="final-line">OFERTA CUPO LIMITADO </p>
      </div>
    </React.Fragment>
  )
  return (
    <div className="Popup-container">
      <div className="Popup-body">
        <div className="Popup-logo">
          <img src="/assets/images/Popup-logo1.png" alt="imgLogo" />
        </div>
        <div className="animacion"></div>
        <div className="Popup-content">
          <div className="Popup-text">
            <h2><span>{t("Aprende")}, </span>{t("cultiva y crece tus conocimientos")}.</h2>
            <h3>{t("Recibe información y ofertas exclusivas inscribiendote aquí.")}</h3>
          </div>
          <div className="Popup-form">
            <iframe id="iframe-form" src="https://www.cognitoforms.com/f/yRGlf3JUGkmcNe4fJqtyWw/52" style={{border:"0",width:"100%" }} />
          </div>
        </div>
      </div>
      <div className="Popup-divider"></div>
      <div className="Popup-footer">
        <div className="Popup-footer-left">
          <div className="canna-ico1"></div>
          <p>{t("paciente: Únete a explorar los beneficios del cannabis medicinal")}.</p>
        </div>
        <a className="Popup-button" href="https://cannaidpr.com/interesados-cw" target="_blank"><p>{t("SOLICITA O RENUEVA TU LICENCIA")} X $35</p></a>
        <p>
          <p className="Popup-moreinfo">{t("mas información")}</p>
          <p className="Popup-tel"><img src="/assets/images/Popup-tel-ico.png" alt="telIco" /> (939)336-1443</p>
        </p>
      </div>
    </div>
  );
};

export default PopupS;
