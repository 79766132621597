// eslint-disable-next-line no-alert
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translation_en from './locale/en/translation.json'

import { getCurrentLanguage } from './utilities/utils/metaData'
let locale = getCurrentLanguage().code ? getCurrentLanguage().code : 'es'

i18n
  .use(initReactI18next).init({
    lng: locale,
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    // do not load a fallback to have the key being the fallback instead
    fallbackLng: "es",
    resources: {
      en: {
        translation: translation_en
      }
    }
})
